export const fetchGraphData = async (transformedData) => {
  //const response = await fetch(`/api/graph?dataset=${selectedValue}`);
  //const response = await fetch(`http://127.0.0.1:7000/plot/`);

  const response = await fetch('https://app.hgqn.de/plot/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(transformedData)
        });
  return response.json();
};
