import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux'
import { Select, Button, Grid } from "@mui/material"; // Example using Material UI
import { fetchGraphData } from "../api/fetchGraphData"; // Assume API call function
import API from '../api/fetchAPI'

import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl';

import Plot from 'react-plotly.js';


const GraphPage = () => {
  const [selectedValue, setSelectedValue] = useState("");
  const [graphData, setGraphData] = useState(null);
  const [labs, setLabs] = React.useState(null)
  const [lab, setLab] = React.useState('')
  const [startDate, setStartDate] = React.useState('')
  const [endDate, setEndDate] = React.useState('')
  const [casesData, setCasesData] = React.useState()
  const [isLoading, setIsLoading] = useState(false); // Track loading state
  const user = useSelector((state) => state.user)

  const handleFetchGraph = async () => {
    // Set busy cursor
    setIsLoading(true);  // Start loading

    console.log("Fetching Cases Data...");
    try {
      await loadCases(); // Ensure loadCases completes before proceeding
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  // Fetch graph data when casesData is updated
  useEffect(() => {
    if (casesData) {
       
        console.log("Fetching Graph Data...");
        if (casesData.length > 0) {
           // Set busy cursor
           setIsLoading(true); // Stop loading

          try {
            fetchGraphData(casesData)
              .then(response => {
                  console.log(response);
                  setGraphData(JSON.parse(response));
              }).catch(error => console.error("Error fetching graph data:", error));
          } finally {
              // Reset cursor to default after fetching is done
              setIsLoading(false); // Stop loading

          }
        } else  {
            setGraphData(null);
           // Show error 'No case data avaialbe from the selected options'
        }
    }
  }, [casesData]); // Runs when casesData updates

  const handleStartDateChange = (event) => {
        console.log("HANDLE START DATE CHANGE")
        console.log(event.target.value)
        setStartDate(event.target.value)
    }

  const handleEndDateChange = (event) => {
        console.log("HANDLE END DATE CHANGE")
        console.log(event.target.value)
        setEndDate(event.target.value)
    }

  const handleLabChange = (event) => {
        console.log("HANDLE LAB CHANGE")
        console.log(event.target.value)
        setLab(event.target.value)
    }
  const loadLabData = () => {
    if(user && user.isSuperuser === true) {
        API.get('/api/labs/list/').then(response => {
            console.log(response)
            setLabs(response.data)
        })
      } else if(user)  {
        console.log(user.lab)
        API.get(`/api/labs/get/${user.lab}`).then(response => {
          console.log(response)
          setLabs(response.data)
      })
     }
    }

  const loadCases = () => {
    console.log(lab)
    API.get(`/api/labs/get/cases/${lab}`, { doNotThrowFor: [404] }).then(response => {

      console.log('REsponse for lab case data API')
      console.log(lab)
      console.log(response)

      // Filter cases based on the selected start and end dates
      const filteredCases = response.cases.filter((caseItem) => {
      console.log(`Parsing befunddatum for case ID: ${caseItem.internalCaseId}:`, caseItem.befunddatum); // Log the befunddatum to verify
      const caseTimestamp = Date.parse(caseItem.befunddatum); // Use Date.parse for more reliable parsing

      console.log(caseTimestamp)
      const start = new Date(startDate);
      const end = new Date(endDate);


      //  Check if the 'befunddatum' is a valid Date
      if (isNaN(caseTimestamp)) {
        console.error(`Invalid date for case ID: ${caseItem.internalCaseId}, befunddatum: ${caseItem.befunddatum}`);
        return false; // Skip invalid dates
      }

       const caseDate = new Date(caseTimestamp); // Assuming `date` is in ISO string or Date format


                    // If both startDate and endDate are selected, filter based on the range
                    if (startDate && endDate) {
                        console.log(caseDate >= start && caseDate <= end)
                        return caseDate >= start && caseDate <= end;
                    }

                    // If only one date is provided (startDate or endDate), use that to filter
                    if (startDate) {
                        console.log(caseDate >= start)
                        return caseDate >= start;
                    }

                    if (endDate) {
                        return caseDate <= end;
                    }

                    // If no date filters, return all cases
                    return true;
                });

                const transformedData = filteredCases.map((caseItem) => ({
                    id: caseItem.internalCaseId,  // Convert internalCaseId to integer
                    //hpo_terms: caseItem.hpoTerms.join("; "),   // Convert HPO terms array to a string
                    novel_gene: false,                   // Placeholder: Adjust based on logic
                    solved: caseItem.caseStatus ? caseItem.caseStatus : 'unclear', // Map caseStatus to is_solved
                    //age_group: (caseItem.ageInYears > 17) ? 'adult' : 'child' ,  // Map age
                    disease_category: caseItem.diseaseCategory ? caseItem.diseaseCategory : 'unspecified'
                }));

                console.log(transformedData)
                setCasesData(transformedData)
            //}
        }).catch(e => {
            console.error(`Could not load lab data for id '${lab._id}'`)
            console.error(e)
            //navigate('/notfound')
        })


    }
   useEffect(() => {
    loadLabData()
   }, []); 
  return (
    <div style={{ cursor: isLoading ? "wait" : "default" }}> {/* Apply cursor based on state */}
        <h2>Auswählen von Daten für das Diagramm</h2>
        {/* Simple Date Pickers (Native HTML Date Inputs) */}
  
      <Grid container spacing={2} alignItems="center" justifyContent="space-between">
        <Grid item xs={12}>
        <label
        style={{ marginRight: "11px", width: "10%", padding: "10px", fontSize: "16px" }}>
        Start Befunddatum :
        </label>
        <input
          type="date"
          value={startDate}
          onChange={handleStartDateChange}
        />
        </Grid>
        <Grid item xs={12}>
          
        <label
        style={{ marginRight: "20px", width: "10%", padding: "10px", fontSize: "16px" }}>
        End Befunddatum :   
        
        </label>
        <input
          type="date"
          value={endDate}
          onChange={handleEndDateChange}
        />
        </Grid>
      </Grid>

      <p>
      <FormControl variant="filled" style={{ width: '100%' }}>
          <InputLabel id="lab-select-label">Lab</InputLabel>
          <Select
            labelId="lab-select-label"
            value={lab}
            onChange={handleLabChange}
          >
            {labs ? labs.map(lab => (
              <MenuItem key={lab._id} value={lab._id}>
                {lab.name}
              </MenuItem>
            )) : <MenuItem disabled>Labore werden geladen …</MenuItem>}
          </Select>
      </FormControl>
      </p>

      <div>
      <Button
        variant="contained"
        color="primary"
        onClick={handleFetchGraph}
        disabled={isLoading}
        style={{ marginTop: "20px", width: "10%", padding: "10px", fontSize: "16px" }}
      >
      {isLoading ? "Fetching..." : "Diagramm abrufen"}
      </Button>
      </div>

        <div>
        <p>
          {graphData ? (
            <Plot data={graphData.data} layout={graphData.layout} />
          ) : (
            <p>{isLoading ? "Loading..." : "Keine Diagrammdaten verfügbar"}</p>
          )}
          </p>
        </div>
      </div>
  );
};


const PlotlyGraph = () => {


  const [graphData, setGraphData] = useState(null);

  useEffect(() => {
    fetch('/plot')
      .then(response => response.json())
      .then(data => {
        setGraphData(JSON.parse(data));
      });
  }, []);

  return (
    <div>
      {graphData ? (
        <Plot data={graphData.data} layout={graphData.layout} />
         ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default GraphPage;
